import { useContext } from "react";
import {Redirect, Route} from "react-router-dom";
import {AuthContext} from "services/providers/auth-provider";

function PrivateRoute({children, permissions, ...rest}) {
  const {isAuthenticated, accessRights} = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          permissions >= accessRights ?
            children({...props})
            :
            <Redirect to={"/no-access"}/>
        ) : (
          <Redirect
            to={{pathname: "/login", state: {referer: "/"}}}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
