import React from "react";
import {Container, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "flex",
    justifyContent: "center",
  },
}));

const Maintenance = (props) => {
  const classes = useStyles()

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.line}>
          <img style={{height: 75}} src={process.env.PUBLIC_URL + '/logo_Ordago.png'} alt="Logo"/>
        </Grid>
        <Grid item xs={12} className={classes.line}>
          <Typography variant={"h5"}>
            Nous sommes actuellement en maintenance !!
          </Typography>
        </Grid>
      </Grid>
    </Container>)
}

export default Maintenance;
