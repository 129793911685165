import {CircularProgress, Container, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  line: {
    display: "flex",
    justifyContent: "center",
  },
}));

const MiniLoading = (props) => {
  const classes = useStyles()
  return (
    <Container maxWidth={"xs"}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.line}>
          <CircularProgress size={props.size} color={props.color}/>
        </Grid>
      </Grid>
    </Container>)
}

export default MiniLoading;
