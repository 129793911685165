import { createContext, useState } from "react";
import Cookies from 'js-cookie';
import { MuiThemeProvider } from '@material-ui/core/styles';

import {light} from "styles/light";
import {dark} from "styles/dark";

export function getThemeByName(theme) {
  return themeMap[theme];
}

const themeMap = {
  light,
  dark,
};

export const ThemeContext = createContext({});

const ThemeProvider = (props) => {
  const currentTheme = Cookies.get("appTheme") || 'light'
  const [themeName, _setThemeName] = useState(currentTheme);
  const theme = getThemeByName(themeName);

  const setThemeName = (name) => {
    Cookies.set('appTheme', name)
    _setThemeName(name)
  }

  return (
    <ThemeContext.Provider value={{themeName, setThemeName}}>
      <MuiThemeProvider theme={theme}>
        {props.children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
}
export default ThemeProvider;
