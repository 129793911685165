import React, { useState, useContext } from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {
  FormControlLabel, Icon,
  IconButton,
  Menu as MuiMenu,
  MenuItem,
  Switch,
  Typography,
  useMediaQuery,
  Button, Backdrop, Fade, Modal
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ExitToAppRoundedIcon from "@material-ui/icons/ExitToAppRounded";
import WbSunnyRoundedIcon from "@material-ui/icons/WbSunnyRounded";
import Brightness3RoundedIcon from "@material-ui/icons/Brightness3Rounded";

import {ThemeContext} from "services/providers/theme-provider";
import {AuthContext} from "services/providers/auth-provider";
import {LogoSwitchAccount} from "../../logo/logo";
import Cookies from "js-cookie";


const useStyles = makeStyles((theme) => ({
  exitIcon: {
    marginRight: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paperModal: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    width: 400
  },
  cssRoot: {
    color: '#000',
    width: 120,
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: '#e6e3e4',
    },
  }
}));

const MenuControls = (props) => {
  const [open, setOpen] = useState(null);
  const [openSwitch, setOpenSwitch] = useState(false);
  const {isApiModeTest, checkedModeDisabled, checkedModeTest, toggleChecked, parentAccount} = props
  const {themeName, setThemeName} = useContext(ThemeContext);
  const {isAuthenticated, logout} = useContext(AuthContext);
  const [accountSwitch, setAccountSwitch] = React.useState([]);
  React.useEffect(() => {
    let account_switch = (Cookies.get('account_switch') && Cookies.get('account_switch') !== undefined)? JSON.parse(Cookies.get('account_switch')) : []
    if (account_switch.length > 0 && accountSwitch.length === 0) {
      setAccountSwitch(account_switch)
    }
  }, [accountSwitch])

  const handleClick = event => {
    setOpenSwitch(true);
  };

  const handleClose = () => {
    setOpenSwitch(null);
  };

  const classes = useStyles();
  const theme = useTheme();

  const handleThemeChange = () => {
    if (themeName === "dark") {
      setThemeName("light");
    } else {
      setThemeName("dark");
    }
  }

  const swithApiComponent = () => {
    return (<FormControlLabel
      control={
        <Switch
          size="small"
          disabled={checkedModeDisabled}
          checked={checkedModeTest}
          onChange={toggleChecked}
        />}
      label={"Api [Mode test]"}
    />)
  }

  const bugReport = () => {
    let urlBugReport = 'https://forms.monday.com/forms/198daa4553ba81f902e715eb7a35bbed?r=use1'
    return (
      <>
      <IconButton color="secondary" aria-label="Bug report" href={urlBugReport} target='_blank'>
        <Icon>bug_report</Icon>
      </IconButton>
      </>
      )
  }

  const SwitchAccount = () => {
    return (
      <>
       {parentAccount.picto != null && accountSwitch.length > 0 && (
      <div  style={{ position: "relative" }}>
        <Button
          variant="contained" color="secondary"
          onClick={handleClick}
           className={classes.cssRoot}
        >
          <img
          style={{paddingRight: 5, height: 31}}
          src={`${process.env.PUBLIC_URL}/logo/color/${parentAccount.picto}`}
          />
          {parentAccount.slug}
        </Button>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openSwitch}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        >
          <Fade in={openSwitch}>
            <div className={classes.paperModal} style={{textAlign: 'center'}}>
              <LogoSwitchAccount style={{paddingRight: 30, height: 31, cursor: 'grab'}} folder={'color'} AuthContext={AuthContext} accountSwitch={accountSwitch} />
            </div>
          </Fade>
        </Modal>
      </div> )}
      </>
    )
  }

  const themeComponent = (responsive) => {
    return (
      <>
        {responsive ?
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={themeName === "dark"}
                onChange={handleThemeChange}
              />}
            label={"Thème sombre"}
          />
          :
          <IconButton onClick={handleThemeChange} color={"inherit"}>
            {themeName === "dark" ?
              <WbSunnyRoundedIcon/>
              :
              <Brightness3RoundedIcon/>}
          </IconButton>
        }
      </>
    );
  }

  const logoutComponent = (responsive) => {
    return (
      <>
        {responsive ?
          <>
            <ExitToAppRoundedIcon className={classes.exitIcon}/>
            <Typography>Se déconnecter</Typography>
          </>
          :
          <IconButton onClick={() => logout()} color="inherit">
            <ExitToAppRoundedIcon/>
          </IconButton>
        }
      </>
    );
  }

  const controls = [
    {
      display: (isApiModeTest && !isApiModeTest.env_prod),
      component: swithApiComponent,
      menuClick: undefined
    },
    {
      display: true,
      component: themeComponent,
      menuClick: undefined
    },
    {
      display: true,
      component: bugReport,
      menuClick: undefined
    },
    {
      display: true,
      component: SwitchAccount,
      menuClick: undefined
    },
    {
      display: isAuthenticated,
      component: logoutComponent,
      menuClick: logout
    }
  ]

  return (
    <>
      {
        !useMediaQuery(theme.breakpoints.up('sm')) ?
          <>
            <IconButton onClick={e => setOpen(e.currentTarget)}>
              <MoreVertIcon/>
            </IconButton>
            <MuiMenu
              anchorEl={open}
              keepMounted
              open={Boolean(open)}
              onClose={_ => setOpen(null)}
            >
              {controls.map((control, i) => (
                control.display &&
                <MenuItem key={i} onClick={_ => control.menuClick ? control.menuClick : setOpen(null)}>
                  {control.component(true)}
                </MenuItem>
              ))}
            </MuiMenu>
          </>
          :
          <>
            {controls.map((control, i) => (
              control.display &&
              <div key={i}>
                {control.component(false)}
              </div>
            ))}
          </>
      }
    </>
  );
}

export default MenuControls;
