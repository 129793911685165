import React from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import axios from "../../axiosConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  }
}));

const Error = (props) => {
  const classes = useStyles()

  return (
    <Grid className="centerDiv">
      <Grid container className={classes.root} spacing={1}>
        <Grid item xs={12}>
          <Typography>Une erreur est survenue... </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{props.message}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant={"contained"} color={"secondary"} onClick={() => window.location.reload()}>
            Ressayer
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Error;
