import {createTheme, responsiveFontSizes} from "@material-ui/core";

export let dark = createTheme({
  palette: {
    type: "dark",
  },
});


dark = responsiveFontSizes(dark)
