import { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import axios from "axios";
import * as _ from "lodash";
import {Link} from "react-router-dom";
import Cookies from "js-cookie";
import {useTheme} from "@material-ui/core/styles";
import {
  AppBar,
  Collapse,
  Divider,
  Drawer,
  Grid,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";

import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';

import {ModeContext} from "services/providers/apimode-provider";
import {AuthContext} from "services/providers/auth-provider";
import MenuControls from "./components/menu-controls";

import {useStyles} from "./menu-style";

import {MiniLoading} from "../loading";
import {LogoForTools, PictoForTools} from "../logo/logo";


const formatLinks = (links) => {
  let cat_apps = _.groupBy(links, "category")
  let l = {}
  Object.keys(cat_apps).forEach(cat => {
    if (!Object.keys(l).includes(cat)) {
      l[cat] = []
    }
    cat_apps[cat].forEach(o => {
      if (o.sub_menu) {
        if (l[cat].map(a => a.category).includes(o.category)) {
          l[cat].forEach(a => {
            if (a.category === o.category) {
              a.links.push(o)
            }
          })
        } else {
          l[cat].push({
            front_url: o.category_link.pathname,
            category: o.category,
            label: o.category_label,
            links: [o],
            icon: {
              icon: o.category_link.icon,
              icon_classes: o.category_link.icon_classes
            },
            sub_menu: true
          })
        }
      } else {
        l[cat].push(o)
      }
    })
  })
  return l
}


export const requestLinks = async (application) => {
  return axios.get(window.API_URL + "/api/v1.0/permissions/?application__icontains=" + application).then(response => {
    if (response.data.length > 0) {
      return formatLinks(response.data[0].sub_apps)
    }
  })
}

export default function Menu(props) {
  const {application, localControls} = props

  const {isApiModeTest, sendChecked} = useContext(ModeContext);
  const {isAuthenticated} = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [links, setLinks] = useState(undefined);
  const [subMenu, setSubmenu] = useState(-1);
  const [checkedModeTest, setCheckedModeTest] = useState(false);
  const [checkedModeDisabled, setCheckedModeDisabled] = useState(true);
  // const [parentAccount, setParentAccount] = Cookies.get('parent_account') != undefined ? useState(JSON.parse(Cookies.get('parent_account'))) : useState({});
  const [parentAccount, setParentAccount] = useState(Cookies.get('parent_account') != undefined ? JSON.parse(Cookies.get('parent_account')): {})

  const classes = useStyles();

  useEffect(() => {
    if (isAuthenticated && !links) {
      if (application.sub_apps.length > 0) {
        setLinks(formatLinks(application.sub_apps))
        setLoading(false)
      } else {
        requestLinks(application.application).then(l => {
          if (l) {
            setLinks(l)
          }
          setLoading(false)
        })
      }
    }
  }, [isAuthenticated, application, links])


  useEffect(() => {
    if (isApiModeTest) {
      setCheckedModeTest(isApiModeTest.mode_api_test)
    }
    setCheckedModeDisabled(!(window.location.pathname === '/login'))
  }, [isApiModeTest, checkedModeDisabled, props])

  const toggleChecked = () => {
    setCheckedModeTest((prev) => !prev);
    let checkedMode = checkedModeTest === false
    Cookies.set('SEWAN_TEST_URL', checkedMode)
    sendChecked(checkedMode)
  };


  const handleOpenSubMenu = (event, id) => {
    event.stopPropagation()
    if (open) {
      if (subMenu === id) {
        setSubmenu(-1)
      } else {
        setSubmenu(id)
      }
    }
  }

  const handleCloseDrawer = () => {
    setOpen(false)
    setSubmenu(-1)
  }

  const displayMenu = () => {
    return (links && Object.keys(links).length !== 0) || typeof localControls !== typeof undefined
  }

  const matches = useMediaQuery(useTheme().breakpoints.up("md"))


  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <Grid container>
            <Grid container>
              <Snackbar
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                open={isApiModeTest && isApiModeTest.mode_api_test}
              >
                <MuiAlert elevation={6} variant="filled" severity="error" className={classes.red}>
                  Api en mode test
                </MuiAlert>
              </Snackbar>
            </Grid>
            <Grid item xs={10} sm={7} md={9} className={classes.inline}>
              {displayMenu() &&
              <IconButton
                color="inherit"
                onClick={() => setOpen(true)}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
              >
                <MenuRoundedIcon/>
              </IconButton>
              }
              {matches ?
                <LogoForTools style={{paddingRight: 15, height: 31}} folder={'white'} />
                :
                <PictoForTools style={{paddingRight: 15, height: 31}} folder={'white'} />
              }
              <Typography variant="h4" noWrap className={clsx(classes.bold, {[classes.title]: !matches})}>
                {props.title ? props.title : application.label}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={5} md={3} className={classes.controls}>
              <MenuControls
                isApiModeTest={isApiModeTest}
                checkedModeTest={checkedModeTest}
                checkedModeDisabled={checkedModeDisabled}
                parentAccount={parentAccount}
                toggleChecked={toggleChecked}
                {...props}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {displayMenu() &&
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleCloseDrawer}>
            <ChevronLeftRoundedIcon/>
          </IconButton>
        </div>
        <Divider/>
        {loading ?
          <MiniLoading/>
          :
          Object.keys(links).map((cat, i) => (
            <div key={i}>
              <List
                subheader={(open && !Object.keys(links[cat]).includes('links') && links[cat].length > 1) &&
                <ListSubheader className={classes.category}>
                  {links[cat][0].category_label}
                </ListSubheader>}
              >
                {links[cat].map((link, j) => (
                  <Grid container key={j} alignItems={"center"}>
                    <ListItem button>
                      <Grid item xs={11} className={classes.center}>
                        <Link
                          to={{pathname: link.front_url, state: {category: link.sub_menu ? link.category : null}}}
                          className={classes.noLink}

                        >
                          <ListItemIcon className={classes.icon}>
                            {!open ?
                              <Tooltip title={link.label} placement={"right"}>
                                <Icon className={link.icon.icon_classes}>{link.icon.icon}</Icon>
                              </Tooltip>
                              :
                              <Icon className={link.icon.icon_classes}>{link.icon.icon}</Icon>
                            }
                          </ListItemIcon>
                          {open && <ListItemText primary={link.label}/>}
                        </Link>
                      </Grid>
                      {open &&
                      <Grid item xs={1}>
                        {link.links ?
                          <IconButton onClick={e => handleOpenSubMenu(e, j)} className={classes.smallPadding}>
                            {subMenu === j ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
                          </IconButton>
                          : null}
                      </Grid>}
                    </ListItem>
                    {link.links &&
                    <Collapse in={subMenu === j} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        <Grid container className={clsx(classes.subLink, classes.nested)}>
                          {link.links.map((l, k) => (
                            <Grid item xs={12} key={k}>
                              <Link
                                to={{pathname: l.front_url}}
                                className={classes.noLink}>
                                <ListItem button>
                                  <ListItemIcon>
                                    <Icon className={l.icon.icon_classes}>{l.icon.icon}</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary={l.label}/>
                                </ListItem>
                              </Link>
                            </Grid>
                          ))}
                        </Grid>
                      </List>
                    </Collapse>
                    }
                  </Grid>
                ))}
              </List>
              <Divider/>
            </div>
          ))}
        {props.localControls &&
        <div className={classes.bottom}>
          <Divider/>
          {props.localControls()}
        </div>}
      </Drawer>}
      <main className={classes.content}>
        {props.children}
      </main>
    </div>
  )
}
