import {makeStyles} from "@material-ui/core/styles";

export const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    maxWidth: "100%",
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
    marginTop: theme.spacing(7),
    overflowX: "hidden"
  },
  controls: {
    display: "flex",
    justifyContent: "flex-end"
  },
  inline: {
    display: "inline-flex",
    alignItems: "center"
  },
  bold: {
    fontWeight: "bold"
  },
  noLink: {
    textDecoration: "initial",
    color: theme.palette.text.primary,
    display: "inline-flex"
  },
  nested: {
    paddingLeft: theme.spacing(5),
  },
  red: {
    textAlign: 'center',
    width: 'fit-content',
    [theme.breakpoints.up('sm')]: {
      width: "100%",
    },
  },
  title: {
    fontSize: "1.2rem"
  },
  bottom: {
    position: "absolute",
    bottom: theme.spacing(1),
    width: "100%"
  },
  smallPadding: {
    padding: theme.spacing(0.5)
  },
  icon: {
    alignItems: "center"
  },
  subLink: {
    textAlign: "left"
  },
  center: {
    display: "flex"
  },
  category: {
    fontSize: theme.typography.h5.fontSize,
    textDecoration: "underline"
  }
}));
