import { createContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import {sendTestModeApi, testModeApi} from 'services/security/auth-service'

export const ModeContext = createContext({})

export default function ModeProvider(props) {

  const [isApiModeTest, setIsApiModeTest] = useState(undefined)

  useEffect(() => {
    let mode = Cookies.get("API_mode") || null
    if (!mode) {
      checkApi().then(_ => {
      })
    } else {
      setIsApiModeTest(JSON.parse(mode))
    }
  }, [])

  useEffect(() => {
    if (!Cookies.get("authenticated")) {
      checkApi().then(_ => {
      })
    }
  }, [])

  const checkApi = () => testModeApi().then(res => {
    setIsApiModeTest(res.data)
    Cookies.set("API_mode", JSON.stringify(res.data))
  })

  const sendChecked = checkedModeTest => sendTestModeApi(checkedModeTest)
    .then(res => {
      setIsApiModeTest(res.data)
      Cookies.set("API_mode", JSON.stringify(res.data))
    })


  return (
    <ModeContext.Provider value={{isApiModeTest, sendChecked}}>
      {props.children}
    </ModeContext.Provider>
  )
}
