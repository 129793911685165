import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  form: {
    justifyContent: "center",
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(3)
  }
}));
